import React, { useState, useCallback } from "react"
import * as DiscogsUtils from "../helpers/discogs-utils"
import * as Cache from "../helpers/cache"
import { useMediaQuery, useTheme } from "@material-ui/core"
import GridLayout from "../components/grid-layout"
import ItemDrawer from "../components/item-drawer"
import LoadingBackdrop from "../components/loading-backdrop"
import {
  InstanceReleaseEntry,
  InstanceReleaseList,
} from "../helpers/discogs-types"
import { graphql, useStaticQuery } from "gatsby"
import Page from "../components/page"
import { Helmet } from "react-helmet"

interface GetOpts {
  cursor?: string
}

const getReleases = async (opts: GetOpts) => {
  const cursor = opts.cursor || ""

  return DiscogsUtils.getInstanceItems(0, cursor)
}

const setInstancesCache = (instances: InstanceReleaseList) => {
  let CACHE_KEY = "instances"

  Cache.setItem(CACHE_KEY, JSON.stringify(instances))
}

const getInstancesCache = (): InstanceReleaseList | null => {
  let CACHE_KEY = "instances"

  const json = Cache.getItem(CACHE_KEY)
  if (json) {
    return JSON.parse(json)
  } else {
    return null
  }
}

const CACHE_KEY_CURSOR = "instances-nextPage"

export default function Instances() {
  const buildData = useStaticQuery(graphql`
    query {
      instances {
        nextPage
        items {
          id
          title
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          data {
            imageUrl
            year
            artists {
              id
              name
            }
            labels {
              id
              name
            }
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let initInstances = buildData.instances.items
  let initNextPage = buildData.instances.nextPage

  const [instances, setInstances] = useState<InstanceReleaseList>(() => {
    if (typeof sessionStorage !== "undefined") {
      const cacheInstances = getInstancesCache()
      if (cacheInstances !== null) {
        return cacheInstances
      }
    }
    return initInstances
  })
  const [nextPage, setNextPage] = useState<string>(() => {
    if (typeof sessionStorage !== "undefined") {
      const cacheCursor = Cache.getItem(CACHE_KEY_CURSOR)
      if (cacheCursor !== null) {
        return cacheCursor
      }
    }
    return initNextPage
  })
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [
    selectedInstance,
    setSelectedInstance,
  ] = useState<InstanceReleaseEntry | null>(null)
  const [loadingItem, setLoadingItem] = useState<boolean>(false)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"))

  const siteTitle = buildData.site.siteMetadata.title

  const loadMoreHandler = useCallback(async () => {
    setLoadingPage(true)
    const opts = { cursor: nextPage }
    const response = await getReleases(opts)

    setInstances(prev => {
      const items = [...prev, ...response.items]
      setInstancesCache(items)
      return items
    })
    setNextPage(response.cursor)
    Cache.setItem(CACHE_KEY_CURSOR, response.cursor)

    setLoadingPage(false)
  }, [nextPage])

  const loadReleaseHandler = useCallback(
    async (id: string) => {
      if (!loadingItem) {
        setLoadingItem(true)
        const itemKey = "item-" + id
        let release = Cache.getItemFromCache(itemKey) as InstanceReleaseEntry
        if (!release) {
          release = await DiscogsUtils.getReleaseItem(id)
          Cache.saveItemToCache(itemKey, release)
        }
        setLoadingItem(false)
        setSelectedInstance(release)
      }
    },
    [loadingItem]
  )

  const closeDialogHandler = useCallback(() => {
    setSelectedInstance(null)
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${siteTitle} - Records`}</title>
        <meta
          name="description"
          content="View all of the records in my cool record collection"
        />
      </Helmet>
      <Page navTitle="Records">
        <LoadingBackdrop open={loadingItem} />
        <GridLayout
          type="instances"
          items={instances}
          nextPage={nextPage}
          loadMoreHandler={loadMoreHandler}
          loadingPage={loadingPage}
          loadItemHandler={loadReleaseHandler}
          closeDialogHandler={closeDialogHandler}
          selectedItem={selectedInstance}
          showInstanceLink={true}
          isLoadingItem={loadingItem}
          useFullDialog={isSmallScreen}
        />
        <ItemDrawer
          type="instances"
          item={selectedInstance}
          closeHandler={closeDialogHandler}
          useInstanceLink={false}
        />
      </Page>
    </React.Fragment>
  )
}
